<template>
    <v-card flat :loading="fetchingDropboxState">
        <v-card-title>
            <span class="secondary--text font-weight-bold">Dropbox sync</span>
        </v-card-title>
        <v-card-subtitle>
            <a href="https://docs.nuvolos.cloud/features/file-system-and-storage/mount-dropbox" target="_blank" style="text-decoration: none">
                How does this work?
            </a>
        </v-card-subtitle>
        <v-card-text>
            <div v-if="fetchingDropboxState">
                <p>Checking your Dropbox configuration, please wait...</p>
            </div>
            <div v-else-if="!isDropboxLinked">
                <img src="@/assets/Dropbox.svg" width="100" class="mb-2" />
                <p>To enable integration in Nuvolos, give access to the nuvolos.cloud application:</p>
                <v-btn :href="redirectURI" color="primary"><b>Enable access</b></v-btn>
            </div>
            <v-alert type="success" v-else class="mt-4" prominent>
                <v-row align="center">
                    <v-col class="grow">You have enabled access to your Dropbox account for nuvolos.cloud.</v-col>
                    <v-col class="shrink">
                        <v-btn outlined @click="unlinkDropbox()" class="ml-2">Unlink</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { dropboxEnums } from '@/mixins/dropbox.js'
export default {
    mixins: [dropboxEnums],
    mounted() {
        this.checkDropboxStatus()
    },
    methods: {
        unlinkDropbox() {
            this.$axios
                .delete('/tokens/dropbox')
                .then(() => {
                    this.$router.push({ query: {} })
                    this.$store.dispatch('showSnackBar', { snackBarText: 'Dropbox unlinked!', snackBarIcon: 'check_circle' })
                    this.checkDropboxStatus()
                })
                .catch(e => {
                    this.$store.dispatch('showSnackBar', { snackBarText: 'An error has occured, please try again later.', snackBarIcon: 'error' })
                })
        }
    },
    computed: {
        ...mapState(['userMetadata']),
        dropboxMount() {
            return this.userMetadata?.use_dropbox_mount
        }
    },
    watch: {
        dropboxMount() {
            if (this.dropboxMount) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Successfully enrolled in new Dropbox integration.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
            }
        }
    }
}
</script>
